const links = document.querySelectorAll('a:not([target="_blank"])')

window.addEventListener('pageshow', () => {
	document.body.classList.add('loaded')
})

links.forEach((link) => {
	link.addEventListener('click', (e) => {
		const href = link.href.split('#')[0]
		const location = window.location.href.split('#')[0]
		if (href === location) return
		e.preventDefault()
		e.stopPropagation()
		e.stopImmediatePropagation()
		document.body.classList.remove('loaded')
		setTimeout(() => {
			window.location.href = link.href
		}, 500)
	})
})

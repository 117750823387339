import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'
import 'swiper/css'

const element = document.querySelector('#swiper-partners .swiper')

const swiper = new Swiper(element, {
	modules: [Autoplay],
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
	},
	allowTouchMove: false,
	loop: true,
	speed: 3000,
	spaceBetween: 16,
	slidesPerView: 3,
	slidesPerGroup: 3,
	lazyPreloadPrevNext: 3,
	breakpoints: {
		991: {
			spaceBetween: 32,
			slidesPerGroup: 6,
			slidesPerView: 6,
		},
	}
})
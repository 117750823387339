const addNbspToOrphanWords = (text) => {
	return text.replace(/ ([aAiIouwWzZ]) /g, ' $1&nbsp;')
}

const paragraphs = document.querySelectorAll('p')

paragraphs.forEach((paragraph) => {
	const originalText = paragraph.innerHTML
	const newText = addNbspToOrphanWords(originalText)
	paragraph.innerHTML = newText
})
// Define imasks
const imasks = {
	type: 'imask',
	options: {
		name: {
			mask: /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]+$/,
		},
		phone: {
			mask: '0000[0000000000000000]',
		},
	}
};

export default imasks;
const buttons = {
	down: document.querySelectorAll('.btn-scroll-down'),
	up: document.querySelectorAll('.btn-scroll-up')
}

const scrollDown = (button) => {
	const currentSection = button.closest('section')
	const nextSection = currentSection.nextElementSibling
	window.scrollTo({
		top: nextSection.offsetTop,
		behavior: 'smooth'
	})
}

const scrollUp = () => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth'
	})
}

buttons.down.forEach(button => button.addEventListener('click', () => scrollDown(button)))
buttons.up.forEach(button => button.addEventListener('click', () => scrollUp()))

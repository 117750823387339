const picker = document.querySelectorAll('.language-picker')

picker.forEach(picker => {
	const button = picker.querySelector('button')
	const arrow = picker.querySelector('button::after')
	const list = picker.querySelector('ul')
	const items = list.querySelectorAll('.dropdown-item')
	let active = list.querySelector('.dropdown-item.active')

	const select = (button, item = active) => {
		active = item
		button.innerHTML = active.innerHTML
	}
	select(button)

	const arrowToggle = () => {
		if (button.getAttribute('aria-expanded') === 'true') {
			button.classList.add('active')
		} else {
			button.classList.remove('active')
		}
	}
	arrowToggle()

	button.addEventListener('click', () => {
		arrowToggle()
	})

	items.forEach(item => {
		item.addEventListener('click', () => {
			items.forEach(item => item.classList.remove('active'))
			item.classList.add('active')
			select(button, item)
			setTimeout(() => {
				arrowToggle()
			}, 50)
		})
	})
})
import datepicker from './options/datepicker';
import imasks from './options/imasks';
import regex from './options/regex';
import { validatePolish } from 'validate-polish';

// Define fields
const fields = [
	{
		name: 'name',
		validation: {
			type: regex.type,
			options: regex.options.name,
		}
	},{
		name: 'email',
		validation: {
			type: regex.type,
			options: regex.options.email,
		}
	},{
		name: 'content',
		validation: {
			type: regex.type,
			options: regex.options.content,
		}
	},{
		name: 'phone',
		validation: {
			type: imasks.type,
			options: imasks.options.phone,
		}
	},{
		name: 'orderId',
		validation: {
			type: regex.type,
			options: regex.options.orderId,
		}
	},{
		name: 'city',
		validation: {
			type: regex.type,
			options: regex.options.address,
		}
	}

];

export default fields;
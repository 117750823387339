import Swiper from 'swiper'
import { Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'

const element = document.querySelector('#swiper-statistics .swiper')
const pagination = document.querySelector('#swiper-statistics .swiper-pagination')

const swiper = new Swiper(element, {
	modules: [ Pagination, Autoplay ],
	autoplay: {
		delay: 2000,
		disableOnInteraction: false
	},
	loop: true,
	pagination: {
		el: pagination
	},
	slidesPerView: 1,
})
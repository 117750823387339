import $2 from "jquery";
window.$2 = $2;

"use strict";

let lang = 'pl';

$(function() {
    lang = $('html').attr('lang');
});

function checkFormValidation(fields) {
    let isValid = true;
    const regexErrors = {
        pl: {
            email: 'Nieprawidłowy adres e-mail',
            email2: 'Nieprawidłowy adres e-mail',
            telefon: 'Numer telefonu powinien składać się z {range} cyfr',
            imie: 'Imie powinno się składać z samych liter',
            nazwisko: 'Nazwisko powinno się składać z samych liter',
            numberRange: 'Wartość powinna mieścić się w zakresie '
        },
        en: {
            email: 'Incorrect e-mail address',
            email2: 'Incorrect e-mail address',
            telefon: 'The telephone number should consist of {range} digits',
            imie: 'First name should consist of letters',
            nazwisko: 'Surname should consist of letters',
            numberRange: 'The value should be in the range '
        },
        de: {
            email: 'Falsche E-Mail Adresse',
            email2: 'Falsche E-Mail Adresse',
            telefon: 'Die Telefonnummer sollte aus {range} Ziffern bestehen',
            imie: 'Der Vorname sollte aus Buchstaben bestehen',
            nazwisko: 'Der Nachname sollte aus Buchstaben bestehen',
            numberRange: 'Der Wert sollte im Bereich liegen '
        }
    }

    const requiredErrors = {
        pl: 'To pole jest wymagane',
        en: 'This field is required',
        de: 'Dieses Feld ist erforderlich'
    }

    fields.forEach(function (field) {
        const pattern = $('form').find(`[name="${field.name}"]`).attr('pattern');
        let validationMessage = '';

        if (pattern) {
            const regex = new RegExp(pattern);

            if (regex.test(field.value)) {
                $(`form [name="${field.name}"]`).removeClass('is-invalid');
                $(`form [name="${field.name}"]`).addClass('is-valid');
                validationMessage = '';
            } else {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                if (field.name === 'telefon') {
                    const range = $(`form [name="${field.name}"]`).attr('data-range') || '9';
                    validationMessage = regexErrors[lang][field.name].replace('{range}', range);
                } else {
                    validationMessage = regexErrors[lang][field.name];
                }
            }
        } else {
            if (!field.value && $(`form [name="${field.name}"]`).get(0).hasAttribute('required')) {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                validationMessage= requiredErrors[lang];
            } else if (field.value && $(`form [name="${field.name}"]`).get(0).hasAttribute('min')
                && (parseInt($(`form [name="${field.name}"]`).attr('min'), 10) > parseInt(field.value, 10)
                    || parseInt($(`form [name="${field.name}"]`).attr('max'), 10) < parseInt(field.value, 10))
                && $(`form [name="${field.name}"]`).get(0).hasAttribute('max')) {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                validationMessage= `${regexErrors[lang]['numberRange']}${$(`form [name="${field.name}"]`).attr('min')} - ${$(`form [name="${field.name}"]`).attr('max')}`;
            } else {
                $(`form [name="${field.name}"]`).removeClass('is-invalid');
                $(`form [name="${field.name}"]`).addClass('is-valid');
                validationMessage = '';
            }
        }

        $(`form [name="${field.name}"]`).closest('.form-group').find('.validation-error').text(validationMessage);
    });

    return isValid;
}

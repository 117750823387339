const dropdowns = document.querySelectorAll('.dropdown-select')
window.addEventListener('load', () => {
	dropdowns.forEach(dropdown => {
		const button = dropdown.querySelector('button')
		const input = dropdown.querySelector('input')
		const list = dropdown.querySelector('ul')
		const items = list.querySelectorAll('.dropdown-item')
		let active = list.querySelector('.dropdown-item.active')

		const select = (button, item = active) => {
			active = item
			button.innerHTML = active.innerHTML
		}
		select(button)

		const arrowToggle = () => {
			if (button.getAttribute('aria-expanded') === 'true') {
				button.classList.add('active')
			} else {
				button.classList.remove('active')
			}
		}
		arrowToggle()

		button.addEventListener('click', () => {
			arrowToggle()
		})

		items.forEach(item => {
			item.addEventListener('click', () => {
				items.forEach(item => item.classList.remove('active'))
				item.classList.add('active')
				input.value = item.dataset.value
				select(button, item)
				setTimeout(() => {
					arrowToggle()
				}, 50)
			})
		})
	})
})
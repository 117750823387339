import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

ScrollTrigger.config({
	syncInterval: 999999999,
	autoRefreshEvents: "visibilitychange,DOMContentLoaded,load" // notice "resize" isn't in the list
})
// ScrollTrigger.normalizeScroll(true)

let backgroundElements = {
	earth: document.querySelector('#home-cover #earth'),
	earthSM: document.querySelector('#home-cover #earth-sm'),
	banana: document.querySelector('#home-cover #banana'),
	bananaSM: document.querySelector('#home-cover #banana-sm')
}

window.addEventListener('load', () => {
	setTimeout(() => {

		// Animation - Cover
		// let gsapMmHomeSection = gsap.matchMedia()
		// gsapMmHomeSection.add({
		// 	isDesktop: '(min-width: 990px)',
		// 	isMobile: '(max-width: 991px)',
		// }, (context) => {
		// 	let { isDesktop, isMobile } = context.conditions;
		// 	ScrollTrigger.create({
		// 		trigger: '.section-home',
		// 		pin: '#timeline',
		// 		pinSpacing: false,
		// 		start: isDesktop ? 'top top' : 'center+=100 center',
		// 		end: isDesktop ? '+=200 top' : 'center+=300 center',
		// 		// markers: true,
		// 	})
		// })
		
		gsap.from('#home-cover #banana', {
			scrollTrigger: {
				trigger: '.section-home',
				start: 'top top',
				end: 'top+=50px top',
				scrub: 1,
				toggleActions: 'restart none none reverse'
				// markers: true,
			},
			x: 70,
			rotate: 30,
		})
		
		gsap.from('#home-cover #earth', {
			scrollTrigger: {
				trigger: '.section-home',
				start: 'top top',
				end: 'top+=50px top',
				scrub: 1,
				toggleActions: 'restart none none reverse'
				// markers: true,
			},
			x: -70,
			rotate: 30,
		})
		
		gsap.from('#home-cover #banana-sm', {
			scrollTrigger: {
				trigger: '.section-home',
				start: 'center+=70px center',
				end: 'center+=250px center',
				scrub: 1,
				toggleActions: 'restart none none reverse'
				// markers: true,
			},
			x: 70,
			rotate: 30,
		})
		
		gsap.from('#home-cover #earth-sm', {
			scrollTrigger: {
				trigger: '.section-home',
				start: 'center+=70px center',
				end: 'center+=250px center',
				scrub: 1,
				toggleActions: 'restart none none reverse'
				// markers: true,
			},
			x: -70,
			rotate: 30,
		})

		if (backgroundElements.earth)		{ backgroundElements.earth.style.opacity = 1 }
		if (backgroundElements.earthSM)		{ backgroundElements.earthSM.style.opacity = 1 }
		if (backgroundElements.banana)		{ backgroundElements.banana.style.opacity = 1 }
		if (backgroundElements.bananaSM)	{ backgroundElements.bananaSM.style.opacity = 1 }
		if (backgroundElements.banana)		{ backgroundElements.banana.style.transformOrigin = 'left' }
		if (backgroundElements.bananaSM)	{ backgroundElements.bananaSM.style.transformOrigin = 'left'}

		// Animation - Overlapping Card
		if (document.querySelector('.overlap-element-card')) {
			gsap.from('.overlap-element-card', {
				scrollTrigger: {
					trigger: '.overlap-element-card',
					start: 'top-=250px bottom-=250px',
					end: 'top bottom-=250px',
					scrub: 0.5,
					toggleActions: 'restart none none reverse',
					// markers: true,
				},
				ease: 'none',
				y: 250,
			})
		}

		// Animation - Arrows
		const arrows = document.querySelectorAll('.arrow')
		arrows.forEach((arrow) => {
			const path = arrow.querySelector('path')
			gsap.to(path, {
				scrollTrigger: {
					trigger: arrow,
					start: 'top bottom-=250px',
					end: 'bottom bottom-=250px',
					scrub: 5,
					toggleActions: 'restart none none reverse'
					// markers: true,
				},
				ease: 'none',
				strokeDasharray: 1200,
			})
		})

		// Animation - Overlapping Sections
		if (document.querySelector('.overlap-element')) {
			let gsapMmOverlapElement = gsap.matchMedia()
			gsapMmOverlapElement.add({
				isDesktop: '(min-width: 990px)',
				isMobile: '(max-width: 991px)',
			}, (context) => {
				let { isDesktop, isMobile } = context.conditions;
				gsap.to('.overlap-element', {
					scrollTrigger: {
						trigger: '.overlap-element',
						start:  isDesktop ? 'center center' : 'bottom-=300px center',
						end: isDesktop ? 'center+=482px center' : 'bottom+=457px center',
						scrub: 1,
						pin: '#timeline',
						pinSpacing: false,
						toggleActions: 'restart none none reverse',
						// markers: true,
					},
					ease: 'none',
					marginBottom: isDesktop ? -482 : -757,
				})
			})
		}
	}, 500)

	// Force Window Resize to refresh GSAP ScrollTrigger
	setTimeout(() => {
		window.dispatchEvent(new Event('resize'))
	}, 1000)
})
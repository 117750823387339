import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'

const navigationButtonDisplay = (swiper, prev = null, next = null) => {
	if (!swiper) return
	swiper.isBeginning ? prev?.classList.add('hide') : prev?.classList.remove('hide')
	swiper.isEnd ? next?.classList.add('hide') : next?.classList.remove('hide')
}

const element = document.querySelector('#swiper-reviews .swiper')
const navigation = {
	prev: document.querySelector('#swiper-reviews .swiper-button-prev'),
	next: document.querySelector('#swiper-reviews .swiper-button-next'),
}
const pagination = document.querySelector('#swiper-reviews .swiper-pagination')

const swiper = new Swiper(element, {
	modules: [ Navigation, Pagination ],
	navigation: {
		prevEl: navigation.prev,
		nextEl: navigation.next,
	},
	loop: false,
	spaceBetween: 16,
	slidesPerView: 'auto',
	slidesPerGroupAuto: true,
	breakpoints: {
		1200: {
			spaceBetween: 56,
		},
	},
	pagination: {
		el: pagination,
	},
})
navigationButtonDisplay(swiper, navigation.prev, navigation.next)
swiper.on('slideChange', () => {
	navigationButtonDisplay(swiper, navigation.prev, navigation.next)
})
swiper.on('breakpoint', () => {
	navigationButtonDisplay(swiper, navigation.prev, navigation.next)
})
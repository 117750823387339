import 'animate.css'

const navigation = document.querySelector('header #navigation')
const links = navigation.querySelectorAll('a')
const navItems = navigation.querySelectorAll('.nav-item')
const navbarToggler = document.querySelector('header .navbar-toggler')

navItems.forEach((item, index) => {
	item.classList.add('animate__animated')
	item.style.setProperty('--animate-duration', `1000ms`)
	item.style.setProperty('animation-delay', `${index * 50}ms`)
	item.style.setProperty('animation-timing-function', 'ease-out-quart')
})

navbarToggler.addEventListener('click', () => {
    const body = document.querySelector('body');

    if (navbarToggler.getAttribute('aria-expanded') === 'true') {
        body.classList.add('overflow-hidden');
    } else {
        body.classList.remove('overflow-hidden');
    }

	// navItems.forEach((item, index) => {
	// 	if (navbarToggler.getAttribute('aria-expanded') === 'true') {
	// 		item.classList.add('animate__bounceInUp')
	// 	} else {
	// 		item.classList.remove('animate__bounceInUp')
	// 	}
	// })

});

links.forEach((link) => {
	link.addEventListener('click', () => {
		navbarToggler.click()
	})
})

const showSelector = () => {
	const badge = document.querySelector('header .badge-group')
	badge.classList.remove('hide')
	const links = badge.querySelectorAll('a')
	links.forEach((link) => {
		if (link.href == window.location.href) {
			link.classList.add('active')
		} else if (link.href == window.location.origin + '/user' && window.location.pathname == '/') {
			link.classList.add('active')
		}

		if (
			window.location.pathname != '' &&
			window.location.pathname != '/' &&
			window.location.pathname != '/user' &&
			window.location.pathname != '/business'
		) {
			badge.classList.add('hide')
		}
	})
}
showSelector()

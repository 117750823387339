import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'

const element = document.querySelector('#swiper-about .swiper')
const navigation = {
	prev: document.querySelector('#swiper-about .swiper-button-prev'),
	next: document.querySelector('#swiper-about .swiper-button-next'),
}
const pagination = document.querySelector('#swiper-about .swiper-pagination')

const swiper = new Swiper(element, {
	modules: [ Navigation, Pagination ],
	navigation: {
		prevEl: navigation.prev,
		nextEl: navigation.next,
	},
	loop: true,
	pagination: {
		el: pagination
	}
})
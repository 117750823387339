const containers = document.querySelectorAll('.container-video')

containers.forEach(container => {
	const video = container.querySelector('video')
	const button = container.querySelector('button')

	if (!video) return
	video.addEventListener('play', () => {
		button.classList.add('hide')
	})
	video.addEventListener('pause', () => {
		button.classList.remove('hide')
	})

	button.addEventListener('click', () => {
		video.play()
	})
})
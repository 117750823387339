import { Modal } from 'bootstrap5';

const forms = {
	contact: '#form-contact',
	business: '#form-business',
}
const modals = {
	contact: document.querySelector('#modal-form-contact'),
	business: document.querySelector('#modal-form-business'),
}

$(forms.contact).on('submit-success', function(event, data) {
	const modal = new Modal((modals.contact), {
		keyboard: false,
		focus: true,
	})
	modal.show()
})

$(forms.business).on('submit-success', function(event, data) {
	const modal = new Modal((modals.business), {
		keyboard: false,
		focus: true,
	})
	modal.show()
})
